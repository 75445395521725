import routes           from "../../config/routes";
import ReCAPCHA         from "../../models/ReCAPCHA";

const verify = async (token: string): Promise<ReCAPCHA | undefined> => {
    try {
        const verifyResponse = await fetch(routes.verifyCapcha, {
            method:     "POST",
            headers:    { "Content-Type": "application/json" },
            body:       JSON.stringify({ token })
        });

        return await verifyResponse.json() as ReCAPCHA;
    }
    catch(error) { console.error(error); }
}

export { verify };