import Radio from "./Radio";

export interface RadioGroupProps {
  name:       string;
  current?:   string;
  disabled?:  boolean;

  options:    Array<{ value: string; label: string; }>;
  onChange:   (value: string) => void;
}

const RadioGroup = ( props: RadioGroupProps ) => {
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    props.onChange(event.currentTarget.value);
  }

  return (
    <div>
      { props.options.map(( { label: optionLabel, value } ) => (
        <div key={value}>
          <Radio
            id={value}
            name={props.name}
            label={optionLabel}
            disabled={props.disabled}
            checked={props.current === value}
            onChange={handleChange}
            value={value}
          />
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;