import { useEffect, useState }          from "react";

import Cookies                          from "universal-cookie";
import ReCAPTCHA                        from "react-google-recaptcha";

import Router                           from "./models/Router";
import CommandTypes                     from "./models/CommandTypes";
import { listRouters, executeCommand }  from "./services/router";
import { verify }                       from "./services/capcha";

import RadioGroup                       from "./components/SearchForm/RadioGroup";
import InputIPAddress, { AddressInfo }  from "./components/SearchForm/IPAddressInput";
import LoadingIndicator                 from "./components/Loading";

function App() {
    const cookies                                       = new Cookies(null, { path: "/" });
    
    const [validCapcha, setValidCapcha]                 = useState<boolean>();
    const [acceptedCookies, setAcceptedCookies]         = useState<boolean>();
    const [numberOfExecutions, setNumberOfExecutions]   = useState(0);

    const [loading, setLoading]                         = useState(false);
    const [routers, setRouters]                         = useState<Array<Router>>([]);

    const [router, setRouter]                           = useState(0);
    const [command, setCommand]                         = useState("ping");
    const [address, setAddress]                         = useState<AddressInfo>();
    const [touchAddress, setTouchAddress]               = useState(false);

    const [commandResponse, setCommandResponse]         = useState("");
    
    const acceptCookies     = (): void => {
        cookies.set("accept_cookies", true);
        submit();
    }

    const validateCapcha    = (token: string | null) => {
        if(!token) return;

        verify(token)
            .then(response => { setValidCapcha(response?.success); submit(response?.success); })
    }

    const appendChunk       = (chunk: string): void => setCommandResponse(current => current + chunk);

    const submit            = async (force?: boolean): Promise<void> => {
        if(loading) return;
        
        setTouchAddress(true);
        setCommandResponse("");
        if (!address?.valid) return;
        
        // verify if cookies is accepted
        const acceptedCookies = cookies.get("accept_cookies") ?? false;
        setAcceptedCookies(acceptedCookies);
        if(!acceptedCookies) return;

        setNumberOfExecutions(current => (current + 1) % 10);
        if(!numberOfExecutions) {
            setValidCapcha(undefined);
            return;
        }

        if(!validCapcha && !force) return;
        
        setLoading(true);
        executeCommand(router, command, address, appendChunk)
            .finally(() => setLoading(false));
    }

    // used as constructor
    useEffect(() => {
        if(routers.length === 0) {
            setLoading(true);
            listRouters()
                .then(response => setRouters(response))
                .finally(() => setLoading(false));
        }
    }, []);

    return (
        <>
            <div className="bg-primary flex flex-col items-center p-4">
                <img
                    src="/images/logo.png"
                    alt="wantelco_logo"
                    className="w-64 h-auto md:w-144"
                />

                <form className="w-full max-w-7xl p-1" onSubmit={(e) => e.preventDefault()}>
                    <div className="grid grid-cols-1 gap-3 md:grid-cols-12">

                        {/* command */}
                        <div className="md:col-span-2 flex flex-col md:items-end">
                            <RadioGroup
                                name="radio-group"
                                current={command}
                                disabled={loading}
                                options={[
                                    { label: "BGP", value: CommandTypes.bgp },
                                    { label: "Ping", value: CommandTypes.ping },
                                    { label: "Traceroute", value: CommandTypes.traceroute },
                                ]}
                                onChange={itemValue => setCommand(itemValue)}
                            />
                        </div>

                        {/* address */}
                        <div className="md:col-span-4">
                            <label htmlFor="ip_address" className="block text-sm font-medium" >IP address or prefix:</label>
                            <InputIPAddress
                                touch={touchAddress}
                                withCIDR={command==="bgp"}
                                onChange={newAddress => setAddress(newAddress)}
                            />
                        </div>
                        
                        {/* router */}
                        <div className="md:col-span-4">
                            <label htmlFor="router" className="block text-sm font-medium">Router:</label>
                            <select
                                id="router"
                                name="router"
                                value={router}
                                onChange={(event) => setRouter(parseInt(event.target.value))}
                                className="w-full h-10 px-3 py-2 border border-secondary rounded-md 
                                    focus:outline-none focus:border-2 focus:ring-2 focus:ring-secondary"
                            >
                                {routers.map((router) => <option value={router.id} key={router.id} > {router.label} </option>)}
                            </select>
                        </div>

                        <div className="md:col-span-1 flex items-center ">
                            <button
                                type="button"
                                className="rounded-full py-2.5 px-8 text-sm font-medium bg-black text-white hover:bg-secondary transition disabled:bg-secondaryLight"
                                disabled={loading}
                                onClick={() => submit()}
                            >
                                RUN
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            
            {/* command response */}
            <div className="flex justify-center">
                <div className="w-full max-w-7xl pt-6" style={{ fontFamily: "consolas" }}>
                    {/* show accept cookies */}
                    <div className="rounded-md w-full bg-secondaryLight flex justify-between">
                        { acceptedCookies === false && 
                            <div className="m-6 w-full flex justify-between">
                                <span className="flex items-center text-xl font-bold">Cookies are needed to validate CAPCHAs.</span>

                                <button
                                    type="button"
                                    className="rounded-full border-2 border-black py-2.5 px-8 text-sm font-medium hover:bg-secondary transition"
                                    onClick={acceptCookies}
                                >
                                    ACCEPT
                                </button>
                            </div>
                        }

                        { ( acceptedCookies === true && validCapcha === false ) &&
                            <span className="m-6 text-xl font-bold">It was not possible to validate your identity with CAPCHA. Please try again later.</span>
                        }
                    </div> 

                    { ( acceptedCookies === true && validCapcha === undefined ) &&
                        <div className="w-full flex justify-center">
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPCHA_KEY ?? ""}
                                
                                onChange={validateCapcha}
                                onErrored={() => setValidCapcha(false)}
                            />
                        </div>
                    }


                    <pre className="break-all text-wrap"> { commandResponse } </pre>

                    { loading && <LoadingIndicator /> }
                </div>
            </div>
        </>
    );
}

export default App;