export interface RadioProps {
  id:               string;
  label:            string;
  name?:            string;
  value?:           string;
  checked?:         boolean;
  disabled?:        boolean;

  onChange?:        (event: React.FormEvent<HTMLInputElement>) => void;
}

const Radio = (props: RadioProps) => (
  <div className="flex gap-2 items-center text-sm font-medium">
    <input
      {...props}
      type="radio"
      className="
          peer w-4 h-4 mb-1 border-2 border-black rounded-full appearance-none cursor-pointer
          checked:bg-secondary checked:border-transparent checked:ring-1 checked:ring-secondary
          hover:bg-secondaryLight
          focus:outline-none focus:ring-1 focus:ring-white
          disabled:bg-secondaryLight
        "
    />

    <label htmlFor={props.id} className="cursor-pointer mb-1">{ props.label }</label>
  </div>
);

export default Radio;