import routes           from "../../config/routes";
import Router           from "../../models/Router";
import { AddressInfo }  from "../../components/SearchForm/IPAddressInput";

const listRouters = async (): Promise<Array<Router>> => {
    try {
        const routersData = await fetch(routes.listRoutersEndpoint);
        
        if(routersData.status === 200)  return routersData.json();
        else                            return [];
    }
    catch(error) {
        console.error(error);
        return [];
    }
}

const executeCommand = async (routerId: number, command: string, address: AddressInfo, onChunk: (value: string) => void) : Promise<boolean> => {
    const decoder   = new TextDecoder();
    const params    = new URLSearchParams({ routerId: routerId.toString(), command, address: address.address, type: address.type });

    const response  = await fetch(`${routes.executeCommandEndpoint}?${params}`);
    if(!response.body || response.status !== 200) return false;

    const reader    = response.body.getReader();
    while(true) {
        const { done, value } = await reader.read();

        if (done) return true;
        
        onChunk(decoder.decode(value));
    }
}

export { listRouters, executeCommand };